import { Navigate } from "react-router-dom";
import Icon from "@ant-design/icons/lib/components/Icon";

import {
	AccessSvg,
	AccountingSvg,
	AllBotsSvg,
	ApiKeysSvg,
	BotModulesSvg,
	BotsSvg,
	CandleAnalyticsSvg,
	DashboardSvg,
	ExchangeSvg,
	ExpertSystemSvg,
	FundingSvg,
	GridSvg,
	ManageGroupsSvg,
	MarketCheckSvg,
	MarketsDetailedInfoSvg,
	MultiGridSvg,
	SettingsSvg,
	StatsSvg,
	SwapSvg,
} from "@/shared/assets/icons/navigation";
import {
	CEXAbilities,
	DEXAbilities,
	DEXV2Ability,
	MainPagesAbilities,
	PartyPagesAbilities,
} from "@/shared/constants/abilities";
import { lazily } from "@/shared/utils/lazily";

import { BotCrumbMenuItem } from "./crumbs/bot-crumb-menu-item";
import { BotCrumbTitle } from "./crumbs/bot-crumb-title";
import { PartyCrumbMenuItem } from "./crumbs/party-crumb-menu-item";
import { PartyCrumbTitle } from "./crumbs/party-crumb-title";
import { getCrumbsMenu } from "./crumbs/utils";
import { LoginRedirect } from "./login-redirect";
import {
	CEXRoutes,
	DEXRoutes,
	DEXV2Routes,
	IRouteConfig,
	MainRoutes,
	PartyRoutes,
	StoryRoutes,
} from "./types";
import { getBotMenuItem, getPartyMenuItem, getBotRoutePath, getDefaultCrumbConfig } from "./utils";

// MAIN PAGES

const { AllBots } = lazily(() => import("@/pages/all-bots"));
const { MarketCheck } = lazily(() => import("@/pages/market-check"));
const { Access } = lazily(() => import("@/pages/access"));
const { Teams } = lazily(() => import("@/pages/teams"));
const { ExchangeAccounting } = lazily(() => import("@/pages/exchange-accounting"));
const { CEXBotCreating } = lazily(() => import("@/pages/cex-bot-creating"));
const { DEXBotCreating } = lazily(() => import("@/pages/dex-bot-creating"));
const { DEXNEBotCreating } = lazily(() => import("@/pages/dex-ne-bot-creating"));
const { Stories } = lazily(() => import("@/pages/stories"));

const { ErrorPage } = lazily(() => import("@/pages/error-page"));

// AUTH

const { Login } = lazily(() => import("@/pages/login"));

// PARTY PAGES

const { MarketsDetailed } = lazily(() => import("@/pages/party/markets-detailed"));
const { Dashboard } = lazily(() => import("@/pages/party/dashboard"));
const { Multigrid } = lazily(() => import("@/pages/party/multigrid"));
const { Funding } = lazily(() => import("@/pages/party/funding"));
const { Modules } = lazily(() => import("@/pages/party/modules"));
const { Analytics } = lazily(() => import("@/pages/party/analytics"));
const { Strategies } = lazily(() => import("@/pages/party/strategies"));

// BOTS PAGES

const { CEXBot } = lazily(() => import("@/pages/bots/CEXBot"));
const { DEXBot } = lazily(() => import("@/pages/bots/DEXBot"));
const { DEXV2Bot } = lazily(() => import("@/pages/bots/DEXV2Bot"));

// STORIES PAGES

const { FormDemo } = lazily(() => import("@/pages/stories/form"));
const { DateRangePickerDemo } = lazily(() => import("@/pages/stories/date-picker"));
const { TableDemo } = lazily(() => import("@/pages/stories/table"));
const { WidgetWrapperDemo } = lazily(() => import("@/pages/stories/widget-wrapper"));
const { Toasts } = lazily(() => import("@/pages/stories/toasts"));
const { ModalsDemo } = lazily(() => import("@/pages/stories/modal"));

export const MAIN_PAGES_ROUTES_SCHEME: IRouteConfig[] = [
	{
		path: MainRoutes.Any,
		element: <ErrorPage />,
	},
	{
		path: MainRoutes.Root,
		element: <Navigate replace to={MainRoutes.AllBots} />,
	},
	{
		path: MainRoutes.AllBots,
		element: <AllBots />,
		handle: getDefaultCrumbConfig(MainRoutes.AllBots),

		menuItem: {
			title: "All bots",
			icon: <Icon component={AllBotsSvg} />,
		},
	},
	{
		path: MainRoutes.MarketCheckPage,
		ability: MainPagesAbilities.MarketCheckView,
		element: <MarketCheck />,
		handle: getDefaultCrumbConfig(MainRoutes.MarketCheckPage),

		menuItem: {
			title: "Market check",
			icon: <Icon component={MarketCheckSvg} />,
		},
	},
	{
		path: MainRoutes.AccessPage,
		ability: MainPagesAbilities.AccessView,
		element: <Access />,
		handle: getDefaultCrumbConfig(MainRoutes.AccessPage),

		menuItem: {
			title: "Access",
			icon: <Icon component={AccessSvg} />,
		},
	},
	{
		path: MainRoutes.TeamsPage,
		ability: MainPagesAbilities.TeamsView,
		element: <Teams />,
		handle: getDefaultCrumbConfig(MainRoutes.TeamsPage),

		menuItem: {
			title: "Manage groups",
			icon: <Icon component={ManageGroupsSvg} />,
		},
	},
	{
		path: MainRoutes.ExchangeAccountingPage,
		ability: MainPagesAbilities.AccountingView,
		element: <ExchangeAccounting />,
		handle: getDefaultCrumbConfig(MainRoutes.ExchangeAccountingPage),

		menuItem: {
			title: "Accounting",
			icon: <Icon component={AccountingSvg} />,
		},
	},

	{
		path: MainRoutes.CEXCreatingPage,
		ability: MainPagesAbilities.CEXCreatingView,
		element: <CEXBotCreating />,
		handle: getDefaultCrumbConfig(MainRoutes.CEXCreatingPage),
	},
	{
		path: MainRoutes.DEXCreatingPage,
		ability: MainPagesAbilities.DEXCreatingView,
		element: <DEXBotCreating />,
		handle: getDefaultCrumbConfig(MainRoutes.DEXCreatingPage),
	},
	{
		path: MainRoutes.DEXNECreatingPage,
		ability: MainPagesAbilities.DEXNECreatingView,
		element: <DEXNEBotCreating />,
		handle: getDefaultCrumbConfig(MainRoutes.DEXNECreatingPage),
	},
	{
		path: MainRoutes.Stories,
		element: <Stories />,
		handle: getDefaultCrumbConfig(MainRoutes.Stories),

		children: [
			{
				path: StoryRoutes.DatePickerStory,
				element: <DateRangePickerDemo />,
				handle: getDefaultCrumbConfig(StoryRoutes.DatePickerStory),
			},
			{
				path: StoryRoutes.Form,
				element: <FormDemo />,
				handle: getDefaultCrumbConfig(StoryRoutes.Form),
			},
			{
				path: StoryRoutes.TableStory,
				element: <TableDemo />,
				handle: getDefaultCrumbConfig(StoryRoutes.TableStory),
			},
			{
				path: StoryRoutes.WidgetWrapper,
				element: <WidgetWrapperDemo />,
				handle: getDefaultCrumbConfig(StoryRoutes.WidgetWrapper),
			},
			{
				path: StoryRoutes.ToastsStory,
				element: <Toasts />,
				handle: getDefaultCrumbConfig(StoryRoutes.ToastsStory),
			},
			{
				path: StoryRoutes.Modal,
				element: <ModalsDemo />,
				handle: getDefaultCrumbConfig(StoryRoutes.Modal),
			},
		],
	},
];

export const AUTH_ROUTES_SCHEME: IRouteConfig[] = [
	{
		path: MainRoutes.Any,
		element: <LoginRedirect />,
	},
	{
		path: MainRoutes.Root,
		element: <Navigate replace to={MainRoutes.Login} />,
	},
	{
		path: MainRoutes.Login,
		element: <Login />,
	},
];

export const PARTY_ROUTES_SCHEME: IRouteConfig[] = [
	{
		path: PartyRoutes.Bots,
		element: <AllBots />,
		handle: getDefaultCrumbConfig(PartyRoutes.Bots),

		menuItem: getPartyMenuItem({
			title: "Bots",
			icon: <BotsSvg />,
			section: PartyRoutes.Bots,
		}),
	},
	{
		path: PartyRoutes.MarketsDetailed,
		ability: PartyPagesAbilities.MarketCheckDetailedView,
		element: <MarketsDetailed />,
		handle: getDefaultCrumbConfig(PartyRoutes.MarketsDetailed),

		menuItem: getPartyMenuItem({
			title: "Detailed Markets",
			icon: <MarketsDetailedInfoSvg />,
			section: PartyRoutes.MarketsDetailed,
		}),
	},
	{
		path: PartyRoutes.Dashboard,
		ability: PartyPagesAbilities.DashboardView,
		element: <Dashboard />,
		handle: getDefaultCrumbConfig(PartyRoutes.Dashboard),

		menuItem: getPartyMenuItem({
			title: "Dashboard",
			icon: <DashboardSvg />,
			section: PartyRoutes.Dashboard,
		}),
	},
	{
		path: PartyRoutes.DashboardV2,
		ability: PartyPagesAbilities.DashboardAccountingView,
		element: <Dashboard />,
		handle: getDefaultCrumbConfig(PartyRoutes.DashboardV2),

		menuItem: getPartyMenuItem({
			title: "DashboardV2",
			icon: <DashboardSvg />,
			section: PartyRoutes.DashboardV2,
		}),
	},
	{
		path: PartyRoutes.Multigrid,
		ability: PartyPagesAbilities.MultigridView,
		element: <Multigrid />,
		handle: getDefaultCrumbConfig(PartyRoutes.Multigrid),

		menuItem: getPartyMenuItem({
			title: "Multi Grid",
			icon: <MultiGridSvg />,
			section: PartyRoutes.Multigrid,
		}),
	},
	{
		path: PartyRoutes.Funding,
		ability: PartyPagesAbilities.FundingView,
		element: <Funding />,
		handle: getDefaultCrumbConfig(PartyRoutes.Funding),

		menuItem: getPartyMenuItem({
			title: "Funding",
			icon: <FundingSvg />,
			section: PartyRoutes.Funding,
		}),
	},
	{
		path: PartyRoutes.Modules,
		ability: PartyPagesAbilities.ModulesView,
		element: <Modules />,
		handle: getDefaultCrumbConfig(PartyRoutes.Modules),

		menuItem: getPartyMenuItem({
			title: "Modules",
			icon: <BotModulesSvg />,
			section: PartyRoutes.Modules,
		}),
	},
	{
		path: PartyRoutes.CandlesAnalytic,
		ability: PartyPagesAbilities.CandleAnalyticsView,
		element: <Analytics />,
		handle: getDefaultCrumbConfig(PartyRoutes.CandlesAnalytic),

		menuItem: getPartyMenuItem({
			title: "TV Charts",
			icon: <CandleAnalyticsSvg />,
			section: PartyRoutes.CandlesAnalytic,
		}),
	},
	{
		path: PartyRoutes.Strategies,
		ability: PartyPagesAbilities.StrategiesView,
		element: <Strategies />,
		handle: getDefaultCrumbConfig(PartyRoutes.Strategies),

		menuItem: getPartyMenuItem({
			title: "Strategies",
			icon: <ExpertSystemSvg />,
			section: PartyRoutes.Strategies,
		}),
	},
];

export const CEX_CONTENT_ROUTES_SCHEME: IRouteConfig[] = [
	{
		path: CEXRoutes.Stats,
		ability: CEXAbilities.StatsView,
		handle: getDefaultCrumbConfig(CEXRoutes.Stats),

		menuItem: getBotMenuItem({
			title: "Stats",
			icon: <StatsSvg />,
			botType: "CEX",
			botTab: CEXRoutes.Stats,
		}),
	},
	{
		path: CEXRoutes.Exchange,
		ability: CEXAbilities.ExchangeView,
		handle: getDefaultCrumbConfig(CEXRoutes.Exchange),

		menuItem: getBotMenuItem({
			title: "Exchange",
			icon: <ExchangeSvg />,
			botType: "CEX",
			botTab: CEXRoutes.Exchange,
		}),
	},
	{
		path: CEXRoutes.Settings,
		ability: CEXAbilities.SettingsView,
		handle: getDefaultCrumbConfig(CEXRoutes.Settings),

		menuItem: getBotMenuItem({
			title: "Settings",
			icon: <SettingsSvg />,
			botType: "CEX",
			botTab: CEXRoutes.Settings,
		}),
	},
	{
		path: CEXRoutes.Grid,
		ability: CEXAbilities.GridView,
		handle: getDefaultCrumbConfig(CEXRoutes.Grid),

		menuItem: getBotMenuItem({
			title: "Grid",
			icon: <GridSvg />,
			botType: "CEX",
			botTab: CEXRoutes.Grid,
		}),
	},
	{
		path: CEXRoutes.APIKeys,
		ability: CEXAbilities.ApiKeysView,
		handle: getDefaultCrumbConfig(CEXRoutes.APIKeys),

		menuItem: getBotMenuItem({
			title: "API",
			icon: <ApiKeysSvg />,
			botType: "CEX",
			botTab: CEXRoutes.APIKeys,
		}),
	},
];

export const CEX_ROUTES_SCHEME: IRouteConfig[] = [
	{
		path: getBotRoutePath({ botType: "CEX" }),
		element: <CEXBot />,
		handle: {
			crumb: {
				title: <BotCrumbTitle />,
				menu: getCrumbsMenu(CEX_CONTENT_ROUTES_SCHEME, BotCrumbMenuItem),
			},
		},

		children: CEX_CONTENT_ROUTES_SCHEME,
	},
];

export const DEX_ROUTES_CONTENT_SCHEME: IRouteConfig[] = [
	{
		path: DEXRoutes.Stats,
		ability: DEXAbilities.StatsView,
		handle: getDefaultCrumbConfig(DEXRoutes.Stats),

		menuItem: getBotMenuItem({
			title: "Stats",
			icon: <StatsSvg />,
			botType: "DEX",
			botTab: DEXRoutes.Stats,
		}),
	},
	{
		path: DEXRoutes.Settings,
		ability: DEXAbilities.SettingsView,
		handle: getDefaultCrumbConfig(DEXRoutes.Settings),

		menuItem: getBotMenuItem({
			title: "Settings",
			icon: <SettingsSvg />,
			botType: "DEX",
			botTab: DEXRoutes.Settings,
		}),
	},
	{
		path: DEXRoutes.Funding,
		ability: DEXAbilities.FundingView,
		handle: getDefaultCrumbConfig(DEXRoutes.Funding),

		menuItem: getBotMenuItem({
			title: "Funding",
			icon: <FundingSvg />,
			botType: "DEX",
			botTab: DEXRoutes.Funding,
		}),
	},
];

export const DEX_ROUTES_SCHEME: IRouteConfig[] = [
	{
		path: getBotRoutePath({ botType: "DEX" }),
		element: <DEXBot />,
		handle: {
			crumb: {
				title: <BotCrumbTitle />,
				menu: getCrumbsMenu(DEX_ROUTES_CONTENT_SCHEME, BotCrumbMenuItem),
			},
		},

		children: DEX_ROUTES_CONTENT_SCHEME,
	},
];

export const DEX_V2_ROUTES_CONTENT_SCHEME: IRouteConfig[] = [
	{
		path: DEXV2Routes.Stats,
		ability: DEXV2Ability.StatsView,
		handle: getDefaultCrumbConfig(DEXV2Routes.Stats),

		menuItem: getBotMenuItem({
			title: "Stats",
			icon: <StatsSvg />,
			botType: "DEX_V2",
			botTab: DEXV2Routes.Stats,
		}),
	},
	{
		path: DEXV2Routes.Settings,
		ability: DEXV2Ability.SettingsView,
		handle: getDefaultCrumbConfig(DEXV2Routes.Settings),

		menuItem: getBotMenuItem({
			title: "Settings",
			icon: <SettingsSvg />,
			botType: "DEX_V2",
			botTab: DEXV2Routes.Settings,
		}),
	},
	{
		path: DEXV2Routes.Swap,
		ability: DEXV2Ability.ExchangeView,
		handle: getDefaultCrumbConfig(DEXV2Routes.Swap),

		menuItem: getBotMenuItem({
			title: "Swap",
			icon: <SwapSvg />,
			botType: "DEX_V2",
			botTab: DEXV2Routes.Swap,
		}),
	},
];

export const DEX_V2_ROUTES_SCHEME: IRouteConfig[] = [
	{
		path: getBotRoutePath({ botType: "DEX_V2" }),
		element: <DEXV2Bot />,
		handle: {
			crumb: {
				title: <BotCrumbTitle />,
				menu: getCrumbsMenu(DEX_V2_ROUTES_CONTENT_SCHEME, BotCrumbMenuItem),
			},
		},

		children: DEX_V2_ROUTES_CONTENT_SCHEME,
	},
];

const PARTY_GROUP_ROUTES: IRouteConfig[] = [
	{
		path: "party/:party",
		handle: {
			crumb: {
				title: <PartyCrumbTitle />,
				menu: getCrumbsMenu(PARTY_ROUTES_SCHEME, PartyCrumbMenuItem),
			},
		},
		children: [
			...PARTY_ROUTES_SCHEME,
			...CEX_ROUTES_SCHEME,
			...DEX_ROUTES_SCHEME,
			...DEX_V2_ROUTES_SCHEME,
		],
	},
];

export const ROUTES_SCHEME = [...MAIN_PAGES_ROUTES_SCHEME, ...PARTY_GROUP_ROUTES];
