import classNames from "classnames";

import gif from "../assets/images/loader.gif";

import s from "./loading.module.scss";

interface ILoadingProps {
	className?: string;
}

export const Loading = ({ className }: ILoadingProps) => (
	<div className={classNames(s.loading, className)}>
		<img height={50} src={gif} alt="Loading" />
	</div>
);
